<template>
  <div>
    <AppTable :rows="coldWallet.value" :columns="columns" :is-loading="requestInProgress" :is-pagination="false">
      <template #default="{ column, row, formattedRow }">
        <TableRow :row="row" :column="column" :formatted-row="formattedRow" />

        <span v-if="column.field === 'asset'">
          <b-badge variant="primary" class="text-white"> {{ row.symbol || '' }} </b-badge>
          {{ row.name || '' }} / {{ row.network || '' }}
        </span>

        <!-- Column: Action -->
        <span v-else-if="column.field === 'action'">
          <span>
            <b-button variant="link">
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
                v-b-modal="`modal-default-${row.originalIndex}`"
              />
            </b-button>

            <b-modal :id="`modal-default-${row.originalIndex}`" ok-only centered>
              <b-card-text>
                <div class="d-flex justify-content-center ">
                  <div class="p-1 rounded bg-white">
                    <qrcode-vue :value="row.address" :size="250" level="H" />
                  </div>
                </div>
              </b-card-text>
            </b-modal>
          </span>
        </span>

        <!-- default render cell -->
        <span v-else class="flex-center-align">
          {{ formattedRow[column.field] }}
        </span>
      </template>
    </AppTable>
  </div>
</template>

<script>
import { BButton, VBModal, BBadge } from 'bootstrap-vue'
import QrcodeVue from 'qrcode.vue'

import { mapActions, mapGetters } from 'vuex'
import store from '@/store/index'

import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'
import columns from './config/tableConfig'
import TableRow from '@/components/TableRow.vue'
import AppTable from '@/components/AppTable.vue'

export default {
  name: 'HotWalletsBuySellWalletView',
  components: {
    AppTable,
    BBadge,
    TableRow,
    QrcodeVue,
    BButton,
  },
  directives: {
    'b-modal': VBModal,
  },
  mixins: [waitRequest, validationError],
  data() {
    return {
      columns,
    }
  },

  computed: {
    ...mapGetters({
      coldWallet: 'coldWallet/coldWalletBuySell',
    }),

    direction() {
      return store.state.appConfig.isRTL
    },
  },
  mounted() {
    this.initState()
  },

  methods: {
    ...mapActions({
      fetchColdWalletBuySell: 'coldWallet/fetchColdWalletBuySell',
    }),

    async initState() {
      await this.waitRequest(() => this.fetchColdWalletBuySell().catch(this.checkErrors))
    },
  },
}
</script>

<style lang="scss">
.centered-element {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
</style>
